import { GAME_TYPES } from "@livecasino/core/constants/gameTypes";
import { toolTipRender } from "shared/utils";
import { SIDE_BET_TYPES } from "@livecasino/core/v2/games/BlackJack/constants";

const CDN_URL = process.env.REACT_APP_CDN_URL;
const AMERICAN_BLACKJACK_GAME_URL = process.env.REACT_APP_AMERICAN_BLACKJACK_GAME_URL;
const IMMERSIVE_BLACKJACK_GAME_URL = process.env.REACT_APP_IMMERSIVE_BLACKJACK_GAME_URL;
const EUROPEAN_ROULETTE_GAME_URL = process.env.REACT_APP_EUROPEAN_ROULETTE_GAME_URL;
const AMERICAN_ROULETTE_GAME_URL = process.env.REACT_APP_AMERICAN_ROULETTE_GAME_URL;
const AUTO_ROULETTE_GAME_URL = process.env.REACT_APP_AUTO_ROULETTE_GAME_URL;
const MULTIPLIER_ROULETTE_GAME_URL = process.env.REACT_APP_MULTIPLIER_ROULETTE_GAME_URL;
const CLASSIC_BACCARAT_GAME_URL = process.env.REACT_APP_CLASSIC_BACCARAT_GAME_URL;
const SPEED_DRAGON_TIGER_GAME_URL = process.env.REACT_APP_SPEED_DRAGON_TIGER_GAME_URL;
const PIRATE_PLUNDER_GAME_URL = process.env.REACT_APP_PIRATE_PLUNDER_GAME_URL;
const SICBO_GAME_URL = process.env.REACT_APP_SICBO_GAME_URL;
const ANDAR_BAHAR_GAME_URL = process.env.REACT_APP_ANDAR_BAHAR_GAME_URL;
const CASINO_HOLDEM_GAME_URL = process.env.REACT_APP_CASINO_HOLDEM_GAME_URL;
const ROBIN_THE_FAIR_GAME_URL = process.env.REACT_APP_ROBIN_THE_FAIR_GAME_URL;
const ROBIN_THE_FAIR_2_GAME_URL = process.env.REACT_APP_ROBIN_THE_FAIR_2_GAME_URL;
const TEEN_PATTI_GAME_URL = process.env.REACT_APP_TEEN_PATTI_GAME_URL;
const MULTIPLIER_AUTO_ROULETTE_GAME_URL = process.env.REACT_APP_MULTIPLIER_AUTO_ROULETTE_GAME_URL;
const HI_LO_FOOTBALL_GAME_URL = process.env.REACT_APP_HI_LO_FOOTBALL_GAME_URL;

let PITBOSS_SERVER_URL = process.env.REACT_APP_PUBLIC_PITBOSS_SERVER_URL;
let BACKOFFICE_URL = process.env.REACT_APP_PUBLIC_BACKOFFICE_URL;
let AUTH_URL = process.env.REACT_APP_PUBLIC_AUTH_URL;
let CHAT_BASE_SERVER_URL = process.env.REACT_APP_PUBLIC_CHAT_SERVER_URL;
let BLACKJACK_DMS_URL = process.env.REACT_APP_PUBLIC_BLACKJACK_DMS_URL;
let ROULETTE_DMS_URL = process.env.REACT_APP_PUBLIC_ROULETTE_DMS_URL;
let MULTIPLIER_ROULETTE_DMS_URL = process.env.REACT_APP_PUBLIC_MULTIPLIER_ROULETTE_DMS_URL;
let BACCARAT_DMS_URL = process.env.REACT_APP_PUBLIC_BACCARAT_DMS_URL;
let DRAGON_TIGER_DMS_URL = process.env.REACT_APP_PUBLIC_DRAGON_TIGER_DMS_URL;
let PIRATE_PLUNDER_DMS_URL = process.env.REACT_APP_PUBLIC_PIRATE_PLUNDER_DMS_URL;
let SICBO_DMS_URL = process.env.REACT_APP_PUBLIC_SICBO_DMS_URL;
let ANDAR_BAHAR_DMS_URL = process.env.REACT_APP_PUBLIC_ANDAR_BAHAR_DMS_URL;
let CASINO_HOLDEM_DMS_URL = process.env.REACT_APP_PUBLIC_CASINO_HOLDEM_DMS_URL;
let ROBIN_THE_FAIR_DMS_URL = process.env.REACT_APP_PUBLIC_ROBIN_THE_FAIR_DMS_URL;
let ROBIN_THE_FAIR_2_DMS_URL = process.env.REACT_APP_PUBLIC_ROBIN_THE_FAIR_2_DMS_URL;
let TEEN_PATTI_DMS_URL = process.env.REACT_APP_PUBLIC_TEEN_PATTI_DMS_URL;
let HI_LO_FOOTBALL_DMS_URL = process.env.REACT_APP_PUBLIC_HI_LO_FOOTBALL_DMS_URL;

if (window.location.origin === process.env.REACT_APP_LOCAL_ORIGIN) {
    PITBOSS_SERVER_URL = process.env.REACT_APP_LOCAL_PITBOSS_SERVER_URL;
    BACKOFFICE_URL = process.env.REACT_APP_LOCAL_BACKOFFICE_URL;
    AUTH_URL = process.env.REACT_APP_LOCAL_AUTH_URL;
    CHAT_BASE_SERVER_URL = process.env.REACT_APP_LOCAL_CHAT_SERVER_URL;
    BLACKJACK_DMS_URL = process.env.REACT_APP_LOCAL_BLACKJACK_DMS_URL;
    ROULETTE_DMS_URL = process.env.REACT_APP_LOCAL_ROULETTE_DMS_URL;
    MULTIPLIER_ROULETTE_DMS_URL = process.env.REACT_APP_LOCAL_MULTIPLIER_ROULETTE_DMS_URL;
    BACCARAT_DMS_URL = process.env.REACT_APP_LOCAL_BACCARAT_DMS_URL;
    ANDAR_BAHAR_DMS_URL = process.env.REACT_APP_LOCAL_ANDAR_BAHAR_DMS_URL;
    PIRATE_PLUNDER_DMS_URL = process.env.REACT_APP_LOCAL_PIRATE_PLUNDER_DMS_URL;
    SICBO_DMS_URL = process.env.REACT_APP_LOCAL_SICBO_DMS_URL;
    DRAGON_TIGER_DMS_URL = process.env.REACT_APP_LOCAL_DRAGON_TIGER_DMS_URL;
    CASINO_HOLDEM_DMS_URL = process.env.REACT_APP_LOCAL_CASINO_HOLDEM_DMS_URL;
    TEEN_PATTI_DMS_URL = process.env.REACT_APP_LOCAL_TEEN_PATTI_DMS_URL;
    ROBIN_THE_FAIR_DMS_URL = process.env.REACT_APP_LOCAL_ROBIN_THE_FAIR_DMS_URL;
    ROBIN_THE_FAIR_2_DMS_URL = process.env.REACT_APP_LOCAL_ROBIN_THE_FAIR_2_DMS_URL;
    HI_LO_FOOTBALL_DMS_URL = process.env.REACT_APP_LOCAL_HI_LO_FOOTBALL_DMS_URL;
} else if (window.location.origin === process.env.REACT_APP_LB_ORIGIN) {
    PITBOSS_SERVER_URL = process.env.REACT_APP_LB_PITBOSS_SERVER_URL;
    BACKOFFICE_URL = process.env.REACT_APP_LB_BACKOFFICE_URL;
    BLACKJACK_DMS_URL = process.env.REACT_APP_LB_BLACKJACK_DMS_URL;
    ROULETTE_DMS_URL = process.env.REACT_APP_LB_ROULETTE_DMS_URL;
    MULTIPLIER_ROULETTE_DMS_URL = process.env.REACT_APP_LB_MULTIPLIER_ROULETTE_DMS_URL;
    BACCARAT_DMS_URL = process.env.REACT_APP_LB_BACCARAT_DMS_URL;
    ANDAR_BAHAR_DMS_URL = process.env.REACT_APP_LB_ANDAR_BAHAR_DMS_URL;
    PIRATE_PLUNDER_DMS_URL = process.env.REACT_APP_LB_PIRATE_PLUNDER_DMS_URL;
    SICBO_DMS_URL = process.env.REACT_APP_LB_SICBO_DMS_URL;
    DRAGON_TIGER_DMS_URL = process.env.REACT_APP_LB_DRAGON_TIGER_DMS_URL;
    CASINO_HOLDEM_DMS_URL = process.env.REACT_APP_LB_CASINO_HOLDEM_DMS_URL;
    TEEN_PATTI_DMS_URL = process.env.REACT_APP_LB_TEEN_PATTI_DMS_URL;
    ROBIN_THE_FAIR_DMS_URL = process.env.REACT_APP_LB_ROBIN_THE_FAIR_DMS_URL;
    ROBIN_THE_FAIR_2_DMS_URL = process.env.REACT_APP_LB_ROBIN_THE_FAIR_2_DMS_URL;
    HI_LO_FOOTBALL_DMS_URL = process.env.REACT_APP_LB_HI_LO_FOOTBALL_DMS_URL;
}

const CHAT_URL = CHAT_BASE_SERVER_URL + "/chat";
export const FLAGS_URL = `${CDN_URL}/images/flags`;
export const THUMBNAIL_IMAGES_URL = `${CDN_URL}/images/thumbnails`;

export { CHAT_URL, PITBOSS_SERVER_URL, BACKOFFICE_URL, AUTH_URL, BLACKJACK_DMS_URL, ROULETTE_DMS_URL };

export const GAME_KINDS = {
    BLACKJACK: "Blackjack",
    ROULETTE: "Roulette",
    BACCARAT: "Baccarat",
    DRAGON_TIGER: "DragonTiger",
    PIRATE_PLUNDER: "PiratePlunder",
    SICBO: "SicBo",
    ANDAR_BAHAR: "AndarBahar",
    CASINO_HOLDEM: "CasinoHoldem",
    ROBIN_THE_FAIR: "RobinTheFair",
    TEEN_PATTI: "TeenPatti",
    HI_LO_FOOTBALL: "HiLo"
};

export const ROUND_STATUSES = {
    FINISH: "Finished",
    PROCESSING: "Processing",
    ACTIVE: "Active",
    CANCELED: "Canceled"
};

export const ROUND_STATES = {
    SUCCESS: "success",
    WARNING: "warning"
};

export const GAME_TYPE_URLS = {
    [GAME_TYPES.AMERICAN_BLACKJACK]: {
        game: AMERICAN_BLACKJACK_GAME_URL,
        dms: BLACKJACK_DMS_URL
    },
    [GAME_TYPES.IMMERSIVE_BLACKJACK]: {
        game: IMMERSIVE_BLACKJACK_GAME_URL,
        dms: BLACKJACK_DMS_URL
    },
    [GAME_TYPES.EUROPEAN_ROULETTE]: {
        game: EUROPEAN_ROULETTE_GAME_URL,
        dms: ROULETTE_DMS_URL
    },
    [GAME_TYPES.AUTO_ROULETTE]: {
        game: AUTO_ROULETTE_GAME_URL,
        dms: ROULETTE_DMS_URL
    },
    [GAME_TYPES.MULTIPLIER_ROULETTE]: {
        game: MULTIPLIER_ROULETTE_GAME_URL,
        dms: MULTIPLIER_ROULETTE_DMS_URL
    },
    [GAME_TYPES.SPEED_BACCARAT]: {
        game: CLASSIC_BACCARAT_GAME_URL,
        dms: BACCARAT_DMS_URL
    },
    [GAME_TYPES.SPEED_DRAGON_TIGER]: {
        game: SPEED_DRAGON_TIGER_GAME_URL,
        dms: DRAGON_TIGER_DMS_URL
    },
    [GAME_TYPES.PIRATE_PLUNDER]: {
        game: PIRATE_PLUNDER_GAME_URL,
        dms: PIRATE_PLUNDER_DMS_URL
    },
    [GAME_TYPES.SICBO]: {
        game: SICBO_GAME_URL,
        dms: SICBO_DMS_URL
    },
    [GAME_TYPES.AMERICAN_ROULETTE]: {
        game: AMERICAN_ROULETTE_GAME_URL,
        dms: ROULETTE_DMS_URL
    },
    [GAME_TYPES.ANDAR_BAHAR]: {
        game: ANDAR_BAHAR_GAME_URL,
        dms: ANDAR_BAHAR_DMS_URL
    },
    [GAME_TYPES.CASINO_HOLDEM]: {
        game: CASINO_HOLDEM_GAME_URL,
        dms: CASINO_HOLDEM_DMS_URL
    },
    [GAME_TYPES.ROBIN_THE_FAIR]: {
        game: ROBIN_THE_FAIR_GAME_URL,
        dms: ROBIN_THE_FAIR_DMS_URL
    },
    [GAME_TYPES.ROBIN_THE_FAIR_2]: {
        game: ROBIN_THE_FAIR_2_GAME_URL,
        dms: ROBIN_THE_FAIR_2_DMS_URL
    },
    [GAME_TYPES.TEEN_PATTI]: {
        game: TEEN_PATTI_GAME_URL,
        dms: TEEN_PATTI_DMS_URL
    },
    [GAME_TYPES.MULTIPLIER_AUTO_ROULETTE]: {
        game: MULTIPLIER_AUTO_ROULETTE_GAME_URL,
        dms: MULTIPLIER_ROULETTE_DMS_URL
    },
    [GAME_TYPES.HI_LO_FOOTBALL]: {
        game: HI_LO_FOOTBALL_GAME_URL,
        dms: HI_LO_FOOTBALL_DMS_URL
    }
};

export const ACTION_TYPES = {
    MISTAKE: "Mistake",
    CARD: "Card",
    CHAT: "Chat",
    CARD_CHANGE: "CardChange",
    CHANGE: "Change",
    TECHNICAL: "Technical",
    TALK: "Talk",
    PG: "PG",
    PROCESSING_ACTION: "ProcessingAction",
    FINISH_ACTION: "FinishAction"
};

export const ACTION_STATES = {
    FINISH: "Finish",
    PROCESSING: "Processing",
    ACTIVE: "Active"
};
export const ACTION_STATE_LABELS = {
    [ACTION_STATES.PROCESSING]: "Finish",
    [ACTION_STATES.ACTIVE]: "Start"
};

export const ACTION_DISPLAY_NAMES_BY_TYPE = {
    [ACTION_TYPES.MISTAKE]: "Mistake",
    [ACTION_TYPES.CARD]: "Card",
    [ACTION_TYPES.CHAT]: "Chat",
    [ACTION_TYPES.CARD_CHANGE]: "Card Change",
    [ACTION_TYPES.CHANGE]: "Change",
    [ACTION_TYPES.TECHNICAL]: "Technical",
    [ACTION_TYPES.TALK]: "Talk",
    [ACTION_TYPES.PG]: "PG"
};

export const TAB_KEYS = {
    PLAYERS: "Active Players",
    CHAT: "Chat",
    ACTIONS: "Actions",
    LAST_ROUNDS: "Last Rounds",
    EVENTS: "Events",
    CUTTING_CARD: "Cutting Cards"
};

export const INITIAL_TABLE_CONFIG = {
    toolTipRender: toolTipRender
};

export const SIDE_BETS = {
    [SIDE_BET_TYPES.twentyOnePlusThree]: "21+3",
    [SIDE_BET_TYPES.perfectPair]: "Perfect Pairs",
    [SIDE_BET_TYPES.suitEmUp]: "Suit em up",
    [SIDE_BET_TYPES.luckyLucky]: "Lucky lucky",
    [SIDE_BET_TYPES.busterBlackJack]: "Buster blackjack"
};

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const GAME_STATUSES = {
    Active: 1,
    Paused: 2
};

export const TABLE_STATUSES = {
    Active: 1,
    Closed: 2
};

export const CARD_TYPES = {
    DEALER: "D"
};

export const VIDEO_ADAPTION = {
    AUTO_ADJUST: "deviationOfMean2",
    NONE: "none"
};

export const VIDEO_QUALITY_STREAM_NAMES = {
    HIGH: "HIGH",
    MEDIUM: "MEDIUM",
    LOW: "LOW"
};

export const MODAL_TYPES = {
    PAUSE_GAME: "PAUSE_GAME",
    BLOCK_USER: "BLOCK_USER",
    CANCEL_GAME: "CANCEL_GAME",
    CLEAR_CARDS: "CLEAR_CARDS",
    CLOSE_TABLE: "CLOSE_TABLE",
    MUTE_FOR_USERS: "MUTE_FOR_USERS",
    BLOCK_USER_CHAT: "BLOCK_USER_CHAT",
    SET_NUMBER: "SET_NUMBER",
    MISTAKE: "MISTAKE",
    CARD: "CARD",
    TECHNICAL: "TECHNICAL",
    SET_OUTCOME: "SET_OUTCOME",
    DEALER_LOGIN: "DEALER_LOGIN",
    SET_DICE_OUTCOME: "SET_DICE_OUTCOME",
    RESCAN_ALL: "RESCAN_ALL",
    RESCAN_LAST: "RESCAN_LAST",
    CARD_CHANGE: "CARD_CHANGE"
};

export const ACTION_MODAL_TYPE = {
    Mistake: "MISTAKE",
    Card: "CARD",
    Technical: "TECHNICAL",
    CardChange: "CARD_CHANGE"
};

export const ACTIONS = {
    RESUME: "Resume",
    PAUSE: "Pause",
    OPEN_TABLE: "Open Table",
    CLOSE_TABLE: "Close Table",
    UNMUTE_FOR_USERS: "Unmute for Users",
    MUTE_FOR_USERS: "Mute for Users",
    SET_NUMBER: "Set Number",
    SET_OUTCOME: "Set Outcome",
    SET_DICE_OUTCOME: "Set Outcome",
    CANCEL_GAME: "Cancel Game",
    TABLE_HISTORY: "Table History",
    SPIN_THE_BALL: "Spin the Ball",
    TURN_ON_THE_WHEEL: "Turn On the Wheel",
    TURN_OFF_THE_WHEEL: "Turn Off the Wheel",
    CLEAR_CARD: "Clear Card",
    DEALER_LOGIN: "Dealer Login",
    SHAKE_DICE: "Shake Dice",
    LIVE_STREAM: "Live Stream",
    CAPTURE_THUMBNAIL: "Capture Thumbnail",
    RESCAN_ALL: "Rescan All",
    RESCAN_LAST: "Rescan Last"
};

export const USER_ACTION_TYPES = {
    VIEW: "viewPlayerProfile",
    REMOVE: "removeFromSeat",
    BLOCK: "blockPlayer"
};
export const ERROR_TRANSLATIONS = {
    RECONNECTING: "Reconnecting...",
    YOUR_SESSION_HAS_EXPIRED: "Your session is expired.",
    PLEASE_RELOAD_THE_PAGE: "Please reload the page",
    ACCESS_DENIED: "You have no permission",
    SOMETHING_WENT_WRONG: "Something went wrong",
    YOU_HAVE_NO_ACCESS: "You have no access"
};
//Slider`s styles object
export const SCALABLE_SLIDER_STYLES = {
    handleStyle: {
        backgroundColor: "var(--lc-pitboss__brown_500)",
        border: "none",
        width: ".8em",
        height: ".8em"
    },
    trackStyle: { backgroundColor: "var(--lc-pitboss__brown_500)" },
    activeDotStyle: { backgroundColor: "var(--lc-pitboss__brown_500)" },
    railStyle: { backgroundColor: "gray" },
    dotStyle: {
        border: "none",
        width: "6px",
        height: "6px",
        backgroundColor: "gray"
    }
};
export const CLASSIC_BJ_AVAILABLE_SEATS = 7;

export const EVENTS_NAME = {
    BetsProcessingEnded: "Bets Processing Ended",
    BettingTimerStarted: "Betting Timer Started",
    GameStarted: "Game Started",
    BettingTimerEnded: "Betting Timer Ended",
    GamePaused: "Game Paused",
    GameResumed: "Game Resumed",
    GameCancelled: "Game Cancelled",
    WinningNumber: "Winning Number",
    GameEnded: "Game Ended",
    Warning: "Warning",
    GameMuted: "Game Muted",
    GameUnmuted: "Game Unmuted",
    DealingTime: "Dealing Time",
    DecisionTime: "Decision Time"
};

export const STREAM_SOURCES = {
    LOCAL: "Local Stream",
    NANO_COSMOS: "Nano Cosmos",
    IMAGES: "Images",
    GAME_IFRAME: "Game Iframe"
};

export const MISTAKE_TAGS_STATES_COLORS = {
    [ACTION_STATES.PROCESSING]: {
        bgColor: "var(--lc-pitboss__yellow_400)",
        color: "var(--lc-pitboss__black_900)"
    },
    [ACTION_STATES.ACTIVE]: {
        bgColor: "var(--lc-pitboss__red_500)",
        color: "var(--lc-pitboss__white_900)"
    }
};

export const ACTION_TYPES_TO_PLAY_SOUND = [
    ACTION_TYPES.MISTAKE,
    ACTION_TYPES.CARD,
    ACTION_TYPES.TECHNICAL,
    ACTION_TYPES.CHANGE
];

const COLORS = {
    YELLOW: "#ffb700",
    BLACK: "#000",
    WHITE: "#fff",
    GREY_300: "#333",
    GREY_200: "#4d4d4d",
    GREY_150: "rgb(85, 85, 85)",
    GREY_100: "#e6e6e6"
};
export const theme = {
    token: {
        colorFillAlter: COLORS.GREY_200,
        colorBgBase: COLORS.GREY_300,
        colorPrimary: COLORS.YELLOW,
        colorFillTertiary: COLORS.YELLOW,
        colorFillQuaternary: COLORS.YELLOW,
        colorText: COLORS.GREY_100,
        colorTextPlaceholder: COLORS.GREY_150
    },
    components: {
        Table: {
            colorIcon: COLORS.GREY_100,
            colorBorderSecondary: COLORS.GREY_200,
            colorBgContainer: COLORS.GREY_300
        },
        Input: {
            colorPrimaryHover: COLORS.BLACK,
            colorText: COLORS.BLACK,
            colorIconHover: COLORS.YELLOW,
            colorBgContainer: COLORS.WHITE
        },
        Select: {
            colorPrimaryHover: COLORS.BLACK,
            controlItemBgActive: COLORS.YELLOW,
            colorText: COLORS.BLACK,
            colorBgContainer: COLORS.WHITE,
            colorWhite: COLORS.WHITE,
            colorBgElevated: COLORS.WHITE
        }
    }
};

export const dealerFieldData = [
    {
        name: "barcode",
        errors: ["The dealer is already logged in"]
    }
];

export const expandedMetadata = [
    {
        action: null,
        colName: "Bet Type",
        displayName: "Bet Type",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "betType",
        link: null,
        type: "text"
    },
    {
        action: null,
        colName: "Bet",
        displayName: "Bet",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "bet",
        link: null,
        type: "text"
    },
    {
        action: null,
        colName: "",
        displayName: "",
        isEditable: false,
        isFilterable: false,
        key: "",
        link: null,
        type: ""
    },
    {
        action: null,
        colName: "",
        displayName: "",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "",
        link: null,
        type: ""
    },
    {
        action: null,
        colName: "",
        displayName: "",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "",
        link: null,
        type: ""
    }
];

export const SOCKET_METHOD_INVOKES = {
    CANCEL_GAME: "CancelGame",
    LOGIN_DEALER: "LoginDealer",
    UPDATE_TABLE_STATUS: "UpdateTableStatus",
    UPDATE_GAME_STATUS: "UpdateGameStatus",
    POPUP_FINISH_ACTION: "PopupFinishAction",
    CHANGE_SOUND_STATUS: "ChangeSoundStatus",
    GET_INITIAL_STATE: "GetInitialState",
    CAPTURE_THUMBNAIL: "CaptureThumbnail",
    INITIAL_STATE: "InitialActionsState",
    GAME_SOUND_CHANGED: "GameSoundChanged",
    REQUEST_INITIAL_ACTIONS_STATE: "RequestInitialActionsState",
    TABLE_STATUS_CHANGED: "TableStatusChanged",
    NEW_ROUND_STARTED: "NewRoundStarted",
    ACTION_STATE_CHANGED: "ActionStateChanged",
    EVENTS_CHANGED: "EventsChanged",
    INFO_MESSAGE: "InfoMessage",
    DEALER_LOGGED_IN: "DealerLoggedIn"
};

export const CARD_GAMES_SOCKET_METHOD_INVOKES = {
    CARD_DEALT: "CardDealt",
    CARD_CHANGE_POPUP_FINISH_ACTION: "CardChangePopupFinishAction",
    EMPTY_CARDS: "EmptyCards",
    RESCAN: "Rescan",
    CLEAR_CARD: "ClearCard"
};
