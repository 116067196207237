import React from "react";
import CloseIcon from "@livecasino/core/v2/Backoffice_DMS_Pitboss/svgs/Close";
import ControlButton from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ControlButton";
import PropTypes from "prop-types";
import styles from "components/Modals/styles.module.scss";

const MainModal = ({
    title = "",
    onClose = null,
    onSubmit,
    children,
    cancelButtonText = "",
    confirmButtonText,
    confirmButtonDisabled = false
}) => {
    return (
        <div className={styles.ModalWrapper}>
            <div className={styles.NotificationWrapper}>
                <div className={styles.ModalHeader}>
                    <span>{title}</span>
                    {typeof onClose === "function" && (
                        <span className={styles.CloseIconContainer} onClick={onClose}>
                            <CloseIcon color="var( --lc-pitboss__black_900)" />
                        </span>
                    )}
                </div>
                <div className={styles.ModalBody}>
                    <div className={styles.Message}>{children}</div>
                    <div className={styles.ModalFooter}>
                        {typeof onClose === "function" && (
                            <div className={styles.ModalButton}>
                                <ControlButton fontSize="1em" onClick={onClose}>
                                    {cancelButtonText}
                                </ControlButton>
                            </div>
                        )}
                        <div className={styles.ModalButton}>
                            <ControlButton
                                fontSize="1em"
                                backgroundColor="var(--lc-pitboss__yellow_800)"
                                onClick={onSubmit}
                                disabled={confirmButtonDisabled}
                            >
                                {confirmButtonText}
                            </ControlButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MainModal.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    confirmButtonDisabled: PropTypes.bool
};

export default MainModal;
