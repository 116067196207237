import React, { useMemo } from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import { antdTableColumnsListCreator } from "@livecasino/core/helpers/metadataCreator";
import { DATE_FORMAT, INITIAL_TABLE_CONFIG } from "shared/constants";
import { momentFormatter } from "shared/utils";
import { roundIdRenderer } from "../../../ClickableRoundId/roundIdRenderer";
import formatNumberWithPrecisionWithZeroAndComma from "@livecasino/core/helpers/formatNumberWithPrecisionWithZeroAndComma";
import { renderDealerName } from "../helpers";
const SCROLL_DATA = { y: "calc(100% - 39px)" };

import styles from "./index.module.scss";

const LastRounds = ({ lastRounds, loading, metaData, baseUrlToRedirect, customRendersObj = null }) => {
    const tableConfig = useMemo(
        () => ({
            ...INITIAL_TABLE_CONFIG,
            actions: {
                dateFormatter: function handleDateFormatter(data) {
                    return momentFormatter(data.text, DATE_FORMAT);
                }
            },
            customRenders: {
                roundId: (columnData, rowData) =>
                    roundIdRenderer({
                        columnData,
                        rowData,
                        baseUrlToRedirect
                    }),
                dealerName: renderDealerName,
                ggrPercent: number => formatNumberWithPrecisionWithZeroAndComma({ number }),
                ...customRendersObj
            }
        }),
        [baseUrlToRedirect, customRendersObj]
    );

    return (
        <div className={styles.LastRoundTableWrapper}>
            <Table
                dataSource={lastRounds}
                columns={antdTableColumnsListCreator(metaData, tableConfig)}
                pagination={false}
                loading={loading}
                className="height100"
                scroll={SCROLL_DATA}
                size="small"
                rowKey={record => record.roundId}
            />
        </div>
    );
};
LastRounds.propTypes = {
    lastRounds: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    metaData: PropTypes.array.isRequired,
    baseUrlToRedirect: PropTypes.string.isRequired,
    customRendersObj: PropTypes.object
};

LastRounds.displayName = "LastRounds";

export default LastRounds;
