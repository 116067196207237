import React from "react";
import PropTypes from "prop-types";
import { Badge } from "antd";

import styles from "./index.module.css";

const ClickableRoundId = ({ status = "", text = null, onRoundIdClickCB = null }) => {
    return (
        <div className={styles.RoundIDContainer}>
            <Badge status={status} />
            <div className={styles.Id} onClick={onRoundIdClickCB}>
                {text}
            </div>
        </div>
    );
};

ClickableRoundId.propTypes = {
    status: PropTypes.string,
    text: PropTypes.number,
    onRoundIdClickCB: PropTypes.func
};

export default ClickableRoundId;
