import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";

import { getLabel, renderSelectOptions, required } from "shared/utils";
import { renderShufflerSelectOptions } from "../../helpers";
import { filterSelectOption } from "../../../../shared/utils";

import { ACTION_MODAL_TYPE } from "../../../../shared/constants";

import MainModal from "../MainModal";

import styles from "./index.module.scss";

const REQUIRED_SHUFFLER_ID = [required("shufflerId")];
const REQUIRED_CARD_COLORS = [required("cardColors")];

const CardChange = ({ onSubmit = null, onClose = null, shufflers, cardColors }) => {
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

    const [form] = Form.useForm();

    const handleSubmit = useCallback(() => {
        onSubmit({ ...form.getFieldsValue(), type: ACTION_MODAL_TYPE.CardChange });
        onClose();
    }, [onSubmit, form, onClose]);

    const handleFormValuesChange = (_, allValues) => {
        const { shufflerId, cardColors = [] } = allValues;

        setIsSubmitButtonDisabled(!shufflerId || cardColors.length === 0);
    };

    return (
        <MainModal
            onSubmit={handleSubmit}
            confirmButtonText="Yes"
            cancelButtonText="No"
            onClose={onClose}
            title="Card Change"
            confirmButtonDisabled={isSubmitButtonDisabled}
        >
            <p>To confirm, please fill in the required fields and click Yes</p>
            <Form form={form} layout="vertical" onValuesChange={handleFormValuesChange}>
                <Form.Item label={getLabel("Shuffler")} name="shufflerId" rules={REQUIRED_SHUFFLER_ID}>
                    <Select
                        placeholder="Choose Shuffler Name"
                        showSearch
                        options={renderShufflerSelectOptions(shufflers)}
                        filterOption={filterSelectOption}
                    />
                </Form.Item>
                <Form.Item label={getLabel("Card Color")} name="cardColors" rules={REQUIRED_CARD_COLORS}>
                    <Select
                        mode="multiple"
                        placeholder="Choose Card Color"
                        className={styles.AlignLeft}
                        showSearch
                        options={renderSelectOptions(cardColors, "name", "id")}
                        filterOption={filterSelectOption}
                    />
                </Form.Item>
            </Form>
        </MainModal>
    );
};

CardChange.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    shufflers: PropTypes.array.isRequired,
    cardColors: PropTypes.array.isRequired
};

export default CardChange;
