import React from "react";
import styles from "./MessageTag.module.scss";
import PropTypes from "prop-types";

const MessageTag = ({ text = "", bgColor: backgroundColor = "", color = "" }) => {
    return (
        <div className={styles.MessageTag} style={{ backgroundColor }}>
            <p className={styles.MessageText} style={{ color }}>
                {text}
            </p>
        </div>
    );
};
MessageTag.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string
};

export default MessageTag;
