import React from "react";
import CloseOrOpenTable from "components/Modals/ModalContent/CloseOrOpenTable";
import PauseOrResumeGame from "components/Modals/ModalContent/PauseOrResumeGame";
import CancelGame from "components/Modals/ModalContent/CancelGame";
import MuteForUsers from "components/Modals/ModalContent/MuteForUsers";
import SetNumber from "components/Modals/ModalContent/SetNumber";
import PropTypes from "prop-types";
import ClearCards from "components/Modals/ModalContent/ClearCards";
import { MODAL_TYPES } from "shared/constants";
import DMSMistakeInfoModal from "./DMSMistakeInfoModal";
import SetOutcome from "./SetOutcome";
import DealerLogin from "./DealerLogin";
import SetDiceOutcome from "./SetDiceOutcome";
import RescanAll from "./RescanAll";
import RescanLast from "./RescanLast";
import CardChange from "./CardChange";

const ModalContent = ({
    type = null,
    onClose,
    closedStatusReasons,
    winningOutcomes = [],
    cancelGameStatusReasons,
    onCancelGame = Function.prototype,
    onPauseGame = Function.prototype,
    onMuteGame = Function.prototype,
    onCloseGame = Function.prototype,
    onClearCards = Function.prototype,
    gameConfig = {},
    onSetNumber = Function.prototype,
    onSetOutcome = Function.prototype,
    onSetDiceOutcome = Function.prototype,
    onSubmitCardsChange = Function.prototype,
    roundId = "",
    onSubmitMistake = Function.prototype,
    cardReasons = [],
    cardColors = [],
    technicalReasons = [],
    mistakeReasons = [],
    shufflers = [],
    dealerInfos = [],
    onDealerLogin = Function.prototype,
    handleRescanCards = Function.prototype,
    isDealerLoggedIn = false
}) => {
    switch (type) {
        case MODAL_TYPES.CLOSE_TABLE:
            return (
                <CloseOrOpenTable
                    reasons={closedStatusReasons}
                    onSubmit={onCloseGame}
                    onClose={onClose}
                    gameConfig={gameConfig}
                />
            );
        case MODAL_TYPES.PAUSE_GAME:
            return <PauseOrResumeGame onSubmit={onPauseGame} onClose={onClose} gameConfig={gameConfig} />;
        case MODAL_TYPES.RESCAN_ALL:
            return <RescanAll onSubmit={handleRescanCards} onClose={onClose} gameConfig={gameConfig} />;
        case MODAL_TYPES.RESCAN_LAST:
            return <RescanLast onSubmit={handleRescanCards} onClose={onClose} gameConfig={gameConfig} />;
        case MODAL_TYPES.CANCEL_GAME:
            return <CancelGame reasons={cancelGameStatusReasons} onCancelGame={onCancelGame} onClose={onClose} />;
        case MODAL_TYPES.MUTE_FOR_USERS:
            return <MuteForUsers onSubmit={onMuteGame} onClose={onClose} gameConfig={gameConfig} />;
        case MODAL_TYPES.CLEAR_CARDS:
            return <ClearCards onSubmit={onClearCards} onClose={onClose} />;
        case MODAL_TYPES.SET_NUMBER:
            return <SetNumber onSubmit={onSetNumber} onClose={onClose} roundId={roundId} />;
        case MODAL_TYPES.SET_OUTCOME:
            return <SetOutcome onSubmit={onSetOutcome} onClose={onClose} winningOutcomes={winningOutcomes} />;
        case MODAL_TYPES.SET_DICE_OUTCOME:
            return <SetDiceOutcome onSubmit={onSetDiceOutcome} onClose={onClose} />;
        case MODAL_TYPES.DEALER_LOGIN:
            return (
                <DealerLogin
                    onDealerLogin={onDealerLogin}
                    onClose={onClose}
                    dealerInfos={dealerInfos}
                    isDealerLoggedIn={isDealerLoggedIn}
                />
            );
        case MODAL_TYPES.MISTAKE:
            return (
                <DMSMistakeInfoModal
                    onClose={onClose}
                    title={"MISTAKE"}
                    reasons={mistakeReasons}
                    onSubmit={onSubmitMistake}
                />
            );
        case MODAL_TYPES.CARD:
            return (
                <DMSMistakeInfoModal
                    onClose={onClose}
                    title={"CARD"}
                    reasons={cardReasons}
                    onSubmit={onSubmitMistake}
                />
            );
        case MODAL_TYPES.TECHNICAL:
            return (
                <DMSMistakeInfoModal
                    onClose={onClose}
                    title={"TECHNICAL"}
                    reasons={technicalReasons}
                    onSubmit={onSubmitMistake}
                />
            );
        case MODAL_TYPES.CARD_CHANGE:
            return (
                <CardChange
                    onClose={onClose}
                    shufflers={shufflers}
                    cardColors={cardColors}
                    onSubmit={onSubmitCardsChange}
                />
            );
        default:
            return null;
    }
};

ModalContent.propTypes = {
    type: PropTypes.any,
    closedStatusReasons: PropTypes.array.isRequired,
    winningOutcomes: PropTypes.array,
    cancelGameStatusReasons: PropTypes.array.isRequired,
    gameConfig: PropTypes.shape({
        isGameMuted: PropTypes.bool,
        isGamePaused: PropTypes.bool,
        isGameActive: PropTypes.bool
    }),
    onCancelGame: PropTypes.func,
    onSubmitMistake: PropTypes.func,
    onPauseGame: PropTypes.func,
    onMuteGame: PropTypes.func,
    onCloseGame: PropTypes.func,
    onClearCards: PropTypes.func,
    onSubmitCardsChange: PropTypes.func,
    onSetNumber: PropTypes.func,
    handleRescanCards: PropTypes.func,
    onSetOutcome: PropTypes.func,
    onSetDiceOutcome: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cardReasons: PropTypes.array,
    technicalReasons: PropTypes.array,
    mistakeReasons: PropTypes.array,
    shufflers: PropTypes.array,
    cardColors: PropTypes.array,
    dealerInfos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            nickname: PropTypes.string,
            surname: PropTypes.string,
            barcode: PropTypes.string
        })
    ).isRequired,
    onDealerLogin: PropTypes.func.isRequired,
    isDealerLoggedIn: PropTypes.bool.isRequired
};

export default ModalContent;
