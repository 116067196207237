import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Select, Radio, Input } from "antd";
import { getLabel, renderSelectOptions, required } from "shared/utils";
import MainModal from "./MainModal";

const DURATION_STATUSES = {
    permanent: "permanent",
    temporary: "temporary"
};

const initialFormState = { durationStatus: DURATION_STATUSES.permanent };

const { TextArea } = Input;

const BlockUser = ({ onClose, onSubmit, blockReasons = [], blockDurations = [], playerName = "" }) => {
    const [generateButtonDisabled, setGenerateButtonDisabled] = useState(true);

    const [form] = Form.useForm();

    const handleSubmit = useCallback(() => {
        onSubmit(form.getFieldsValue());
    }, [onSubmit, form]);

    const handleFormValuesChange = (_, allValues) => {
        const { durationStatus, blockDurationSeconds, playerChatBlockType, note } = allValues;

        const isSubmitButtonEnabled =
            note &&
            playerChatBlockType &&
            (durationStatus === DURATION_STATUSES.permanent ||
                (durationStatus === DURATION_STATUSES.temporary && blockDurationSeconds));

        setGenerateButtonDisabled(!isSubmitButtonEnabled);
    };

    return (
        <MainModal
            onSubmit={handleSubmit}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title="Block User"
            confirmButtonDisabled={generateButtonDisabled}
        >
            <div>
                <p> Are you sure you want to block from the game?</p>
                <p>{playerName}</p>
            </div>

            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleFormValuesChange}
                initialValues={initialFormState}
            >
                <Row>
                    <Col span={24} className="marginBottom10">
                        <Form.Item
                            label={getLabel("Block Duration")}
                            name={"durationStatus"}
                            rules={[required("Required")]}
                        >
                            <Row>
                                <Radio.Group defaultValue={"permanent"}>
                                    <Radio value={DURATION_STATUSES.permanent}>Permanent</Radio>
                                    <Radio value={DURATION_STATUSES.temporary}>Temporary</Radio>
                                </Radio.Group>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.durationStatus !== currentValues.durationStatus
                            }
                            rules={[required("Duration Status")]}
                        >
                            {({ getFieldValue }) => {
                                return (
                                    getFieldValue("durationStatus") === "temporary" && (
                                        <Form.Item label={getLabel("Duration")} name="blockDurationSeconds">
                                            <Select
                                                placeholder={"Select duration"}
                                                options={renderSelectOptions(blockDurations)}
                                            />
                                        </Form.Item>
                                    )
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}
                    >
                        <Col span={24}>
                            <Form.Item
                                label={getLabel("Reason")}
                                name="playerChatBlockType"
                                rules={[required("Reason")]}
                            >
                                <Select placeholder={"Select reason"} options={renderSelectOptions(blockReasons)} />
                            </Form.Item>
                        </Col>
                    </Form.Item>
                    <Col span={24}>
                        <Form.Item label={getLabel("Notes")} name="note" rules={[required("Note")]}>
                            <TextArea placeholder="Add Notes" autoSize={{ maxRows: 5 }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </MainModal>
    );
};

BlockUser.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    blockReasons: PropTypes.array,
    blockDurations: PropTypes.array,
    playerName: PropTypes.string
};

export default BlockUser;
