import React from "react";
import MessageTag from "./MessageTag/MessageTag";
import styles from "./Mistakes.module.scss";
import PropTypes from "prop-types";

const Mistakes = ({ mistakes }) => {
    let showMore = false;
    let mistakesToShow = mistakes;

    // show only 3 tags and more
    if (mistakes.length > 3) {
        showMore = true;
        mistakesToShow = mistakes.slice(0, 3);
    }

    return (
        <div className={styles.MistakesMessageWrapper}>
            {mistakesToShow.map((mistake, index) => (
                <MessageTag key={index} text={mistake.text} bgColor={mistake.bgColor} color={mistake.color} />
            ))}
            {showMore && (
                <MessageTag text="..." bgColor="var(--lc-pitboss__yellow_400)" color="var(--lc-pitboss__black_900)" />
            )}
        </div>
    );
};
Mistakes.propTypes = {
    mistakes: PropTypes.array.isRequired
};

export default Mistakes;
